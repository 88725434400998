<template>
    <div class="componentWrapper">
        <h1>Föreläsningar</h1>
        <div class="tabSection">
            <div class="topLeftContainer">
                <div>
                    Att förstå hur vi fungerar och hur vi reagerar på stress skapar insikt om oss själva och våra reaktioner. Förståelse är nyckeln till förändring.
                    <br/>
                    <br/>
                    Jag föreläser om utmattning, stress, hållbarhet och återhämtning för olika verksamheter, inklusive företag, förskolor och skolor. 
                    <br/>
                    <br/>
                    För förskolor fokuserar jag särskilt på relationellt ledarskap, anknytning, barnsyn och förhållningssätt.
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    Kontakta mig för ytterligare information och för kostnadsförslag 
                </div>
            </div>
            <div class="lectureImage sectionImageSmall">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'LectureComponent',
        components : {
        },
        data : () => ({
        })
    }
</script>
<style>
.lectureImage {
    background-image: url('../assets/lecture_holly.jpg')
}
</style>