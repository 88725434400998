<template>
    <div class="componentWrapper">
        <h1>Om mig</h1>
        <div>
            <div class="profileImageWrapper">
                <div class="profileImage"></div>
            </div>
        </div>
        <h1 style="margin-bottom: 0.5rem">Helena Lundgren</h1>
        <h2>Föreläsare & Diplomerad återhämtningsterapeut</h2>
        <div
            class="tabSection"
            style="max-width: 60rem; text-align: center;">
            <div>
                Specialiserad på utmattning, stressrelaterad ohälsa, stresshantering och återhämtning
            </div>
            <div>
                Jag föreläser om utmattning, stress, hållbarhet och återhämtning.
            </div>
            <div>
                Jag kompetensutvecklar även förskolor om relationellt ledarskap, anknytning, barnsyn och förhållningssätt.
            </div>
        </div>

        <div class="tabSection tabSection2" style="font-size: 2rem; text-align: center; margin: 0rem auto">
            <p style="width: 100%">
                ”Långsiktig hållbar utveckling kräver hållbara människor”
            </p>
            <h5>Bodil Jönsson</h5>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'AboutComponent',
        components : {
        },
        data : () => ({})
    }
</script>

<style>
@media only screen and (max-width: 960px) {
    .smallTextSection, .smallTextSectionLeft {
        width: 100% !important;
        margin: unset !important
    }
}
.smallTextSection {
    padding: 1rem 2rem;
    margin-left: 34%;
}
.smallTextSectionLeft {
    padding: 1rem 2rem;
    margin-right: 34%;
}
.profileImageWrapper {
    margin-top: 3rem;
    margin-bottom: 1rem;
    height: 13rem;
    width: 13rem;
    padding: 0.5rem;
    border-radius: 12rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.profileImage {
    background-image: url('../assets/IMG_1629.jpg');
    height: 100%;
    border-radius: 12rem;
    background-size: cover;
    background-position: center;
}
</style>