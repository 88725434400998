<template>
    <div>
        <div class="tabSection">
            <div class="topLeftContainer">
                    Ibland kan det vara lätt att glömma vem den viktigaste personen är i ens liv.
                    Återhämtning kan hjälpa dig att hitta tillbaka till huvudpersonen i ditt
                    liv, du själv.
                    <br/>
                    <br/>
                    Livet händer, saker och ting sker som vi inte har kontroll över. Vardagen blir
                    ibland till ett berg av måsten och det kan vara svårt att se hur vi ska orka ta
                    oss över. Att mitt i allt hitta tid för sitt eget mående kan kännas omöjligt.
                    Det är lätt att stressen tar över och vår energi går åt till allt annat och till
                    andra i vår omgivning. Det kan kännas omöjligt att ens se hur vi ska kunna bryta
                    mönstret.
                    <br/>
                    <br/>
                    Förståelse för varför vi gör som vi gör är ett sätt till läkning och
                    tillfrisknande. Det är alltid du som klient som är i fokus och tillsammans
                    skapar vi förståelse till orsaken för ditt mående. Att förstå varför är grunden
                    till att kunna göra förändringar och att därefter kunna finna ett nytt sätt att
                    leva i harmoni och balans. Genom återhämtning får du ett nytt sätt att
                    förhålla dig till livet.
                </div>
                <div class="therapyImageFlower sectionImageSmall">
                </div>
        </div>
        <div
            class="tabSection"
            style="background-color: #edf3eb; font-size: 2rem; text-align: center; margin: 0 auto">
            Nu är det tid för dig att leva inifrån och ut, istället för utifrån och in!
        </div>
        <div class="tabSection2">
            <h1>Jag erbjuder</h1>
            <div class="sectionBodyWrapper">
                <div class="threeGridColumnWidth">
                    <h2>Samtal</h2>
                    Du är välkommen på samtal i min lokal i Västra Göteborg.
                    <br/>
                    <br/>
                    Samtal erbjuds även digitalt via Zoom.
                    <br/>
                    <br/>
                    <h3>Kostnad</h3>
                    <b>Samtal (60min):</b> 850kr
                    <br/>
                    <br/>
                    <h3>Bokning</h3>
                    Bokning sker via <a href="mailto:helenasdrivbank@gmail.com">mail</a> eller via Boka/Kontakt.
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Verktyg</h2>
                    Jag erbjuder en rad verktyg och metoder som hjälper dig att hitta din egen väg till återhämtning. Oavsett om du vill ha stöd i att utveckla hälsosamma vanor, hitta effektiva sätt att stressa av eller få hjälp att förstå och lyssna på din kropp
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Föreläsningar</h2>
                    Jag föreläser om utmattning, stress, hållbarhet och återhämtning. 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'TherapyComponent',
        components : {},
        data : () => ({})
    }
</script>
<style>
.therapyImageFlower {
    background-image: url('../assets/063A1009.jpg') 
}
</style>