<template>
    <div class="componentWrapper">
        <h1>Välmående</h1>

        <v-tabs class="component__tabs" v-model="tab" background-color="white">
            <v-tab v-for="item in items" :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item :key="0" class="tabWrapper">
              <TherapyComponent />
            </v-tab-item>
            <v-tab-item :key="1" class="tabWrapper">
              <StressComponent/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import TherapyComponent from './TherapyComponent.vue'
    import StressComponent from './StressComponent.vue'

    export default {
        name : 'ConversationComponent',
        components : {
          TherapyComponent,
          StressComponent
        },
        data : () => ({
            tab: null,
            items: ['Återhämtning', 'Stresshantering']
        })
    }
</script>
<style>
</style>