<template>
    <div>
        <div class="home__jumbotronWrapper">
            <div class="home__jumboCardWrapper">
                <div class="home__jumbotronCard">
                    <div class="home__logowrapper">
                        <img class="logo" src="../assets/drivbank_logo.png" alt="logo"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__more">
            <h1>Ta del av vad jag erbjuder</h1>
            <div class="home__moreWrapper">
                <div @click="handleEmit(1)">
                    <Card
                        @click="handleEmit(1)"
                        title="Föreläsningar"
                        sub-title=" "
                        :img-src="require('../assets/forelasningar.png')"
                        text="Jag föreläser om utmattning, stress, hållbarhet och återhämtning. "/>
                    </div>
                    <div @click="handleEmit(2)">
                        <Card
                        @click="handleEmit(2)"
                        title="Återhämtning"
                        sub-title=" "
                        :img-src="require('../assets/IMG_2758.jpg')"
                        text="Ibland kan det vara lätt att glömma vem den viktigaste personen är i ens liv. Återhämtning kan hjälpa dig att hitta tillbaka till huvudpersonen i ditt liv, du själv."/>
                </div>
                <div @click="handleEmit(2)">
                    <Card
                        @click="handleEmit(2)"
                        title="Stresshantering"
                        sub-title=" "
                        :img-src="require('../assets/IMG_0358.png')"
                        text="Det finns många sätt för att lära sig hantera stress, men i grund och botten är stresshantering något annat. Stresshantering handlar främst om att lära känna sig själv."/>
                </div>
            </div>
        </div>
        <div class="tabSection" style="background-color: #edf3eb; font-size: 2rem; text-align: center; margin: 0 auto; display: flex; flex-direction: column;">
            <div>
                Håll dig uppdaterad!
            </div>
            <div style="display: grid; grid-column-gap: 2rem; grid-template-columns: 1fr 1fr 1fr">
                <a href="https://www.linkedin.com/company/helenas-drivb%C3%A4nk/about/" target="_blank">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/768px-LinkedIn_logo_initials.png" style="height: 4rem;"/>
                </a>
                <a href="https://www.instagram.com/helenasdrivbank/" target="_blank">
                    <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fe%2Fe7%2FInstagram_logo_2016.svg%2F1200px-Instagram_logo_2016.svg.png&f=1&nofb=1" style="height: 4rem;"/>
                </a>
                <a href="https://www.facebook.com/HelenasDrivbank" target="_blank">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Facebook_logo_%28square%29.png/640px-Facebook_logo_%28square%29.png" style="height: 4rem;"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Card from './Card'
    export default {
        name : 'HomeComponent',
        components : {
            Card
        },
        data : () => ({}),
        methods: {
            handleEmit(index) {
                this.$emit('tabIndexChange', index)
            },
    }
    }
</script>

<style>
.logo {
    max-height: 11rem;
}

@media only screen and (max-width: 960px) {
    .home__jumbotronWrapper {
        padding: 3rem !important;
    }
}
.home__jumbotronWrapper {
    opacity: 0.89;
    background-image: url('../assets/IMG_8148.jpg');
    background-size: cover;
    background-position: center;
    height: 27rem;
    width: 100vw
}
.home__jumboCardWrapper {
    color: white;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.home__jumbotronCard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
.home__jumboQuote {
    font-size: 1rem;
    max-width: 50rem;
    text-align: center;
}
.home__more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    flex-direction: column;
}
.home__moreWrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.home__logowrapper {
    padding: 1.3rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    background-color: #fffff4;
}
</style>