<template>
    <div id="app" data-app>
        <AppBar @tabIndexChange="handleTabChange" @showDialog="handleDialogValue"/>

        <HomeComponent v-if="currentTabIndex === 0" @tabIndexChange="handleTabChange"/>

        <LectureComponent v-if="currentTabIndex === 1" @tabIndexChange="handleTabChange"/>

        <ConversationComponent v-if="currentTabIndex === 2"/>

        <AboutComponent v-if="currentTabIndex === 3"/>
        <Pricing v-if="currentTabIndex === 4"/>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Kontakta mig</span>
                </v-card-title>
                <v-card-text>
                    Vänligen kontakta mig på antingen <a href = "mailto: helenasdrivbank@gmail.com">helenasdrivbank@gmail.com</a> eller på <a href="tel:+46703279098">0703279098</a>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-footer fixed style="display: flex; justify-content: center; flex-direction: column; padding: 1rem">
            <div>
                <strong>Helenas Drivbänk</strong>
            </div> 
            <div style="font-size: 0.875rem">
                helenasdrivbank@gmail.com - 0703279098 - Askims Utsiktsväg 22, Göteborg
            </div>
        </v-footer>
    </div>
</template>

<script>
    import HomeComponent from './components/HomeComponent.vue'
    import AboutComponent from './components/AboutComponent.vue'
    import AppBar from './components/AppBar.vue'
    import Pricing from './components/Pricing.vue'
    import ConversationComponent from './components/ConversationComponent.vue'
    import * as api from './api/api'
    import LectureComponent from './components/LectureComponent.vue'

    export default {
        name : 'App',
        components : {
            AppBar,
            HomeComponent,
            LectureComponent,
            ConversationComponent,
            AboutComponent,
            Pricing
        },
        data : () => {
            return {
                valid: true,
                currentTabIndex: 0,
                showInformation: true,
                snackbar: true,
                dialog: false,
                messageText: '',
                nameText: '',
                emailText: '',
                phoneText: '',
                rules: [
                    value => !!value || 'Obligatorisk.',
                    value => (value && value.length >= 3) || 'Minimum 3 karaktärer'
                ]
            }
        },
        methods : {
            handleDismiss() {
                this.showInformation = false;
            },
            handleTabChange(indexValue) {
                this.dialog = false
                this.currentTabIndex = indexValue
            },
            handleDialogValue() {
                this.dialog = true
            },
            async sendEmail() {
              const valid = this.$refs.form.validate()
              if (valid) {
                var response = await api.sendEmail(this.nameText, this.emailText, this.messageText, this.phoneText);
                if (response.status === 200) {
                    window
                        .location
                        .reload();
                }
              } else {
                throw new Error('Could not send message, invalid form')
              }
            }
        }
    }
</script>

<style>
    @import url( 'https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
    .theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
        color: rgba(0, 0, 0, 0.94) !important;
    }
    h1 {
        margin-bottom: 1rem;
    }
    h2 {
        margin-bottom: 0.250rem;
    }
    footer {
        border-top: 1px solid rgba(0,0,0,0.05) !important;
        background-color: white !important;
    }
    #app {
        font-family: 'Lato', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: rgba(0,0,0, 0.94);
        padding-bottom: 8rem;
    }
    ul {
        padding-left: 2rem;
    }
    .componentWrapper {
        padding: 5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .component__tabs {
        display: flex;
        justify-content: center;
    }
    .tabWrapper {
        width: 100%;
    }
    .sectionImageSmall {
        max-width: 25rem;
        width: 20rem;
        height: 20rem;
        background-size: cover;
        background-position: center;
        margin-bottom: 2rem;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    .v-toolbar__content {
        background-color: #fffff4 !important;
    }
    @media only screen and (max-width: 960px) {
        .tabSection,
        .tabSection1,
        .tabSection2 {
            padding: 1rem 3rem !important;
        }
        .threeGridColumnWidth,
        .twoColumnWidth {
            width: 89% !important;
        }
    }
    .threeGridColumnWidth,
    .twoColumnWidth {
        text-align: left;
        margin-bottom: 2rem;
    }
    .twoColumnWidth {
        width: 34%;
    }
    .threeGridColumnWidth {
        width: 25%;
    }
    .sectionBodyWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
    }
    .tabSection,
    .tabSection1,
    .tabSection2 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1rem 3rem;
    }
    .tabSection {
        min-height: 12rem;
        margin: 0 auto;
        width: 100%;
    }
    .tabSection1 {
        grid-column-gap: 3rem;
        grid-template-columns: 40% 40%;
        text-align: center;
        font-size: 1.5rem;
    }
    .tabSection2 {
        text-align: center;
        background-color: #f6f3f0;
    }
    .topLeftContainer {
        min-width: 21rem;
        max-width: 55rem;
        margin-bottom: 2rem;
    }
</style>