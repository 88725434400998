<template>
    <div>
        <div class="tabSection">
            <div class="topLeftContainer">
                <div>
                    Stress är en naturlig del av livet, men när den blir långvarig och överväldigande kan den påverka både vår fysiska och psykiska hälsa.
                    I dagens samhälle, där vi ständigt ställs inför krav och förväntningar, kan det vara lätt att känna att vi förlorar kontrollen.
                    <br/>
                    <br/>
                    Men stress behöver inte styra ditt liv. Med rätt verktyg och strategi kan du hantera stressen och återta kontrollen över din vardag.
                    Det första steget i att hantera stress är att förstå den. Vad är det som orsakar stress i ditt liv? Genom att identifiera källorna till din stress kan du börja hitta lösningar.
                </div>
            </div>
            <div class="stressImage sectionImageSmall">
            </div>
    </div>
        <div class="tabSection2" style="padding-top: 8rem;">
            <h1 style="margin-bottom: 2rem;">Jag erbjuder</h1>
            <div class="sectionBodyWrapper">
                <div class="threeGridColumnWidth">
                    <h2>Samtal</h2>
                    Du är välkommen på samtal i min lokal i Västra Göteborg.
                    <br/>
                    <br/>
                    Samtal erbjuds även digitalt via Zoom.
                    <br/>
                    <br/>
                    <h3>Kostnad</h3>
                    <b>Samtal (60min):</b> 850kr
                    <br/>
                    <br/>
                    <h3>Bokning</h3>
                    Bokning sker via <a href="mailto:helenasdrivbank@gmail.com">mail</a> eller via Boka/Kontakt.
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Verktyg</h2>
                    Jag erbjuder en rad effektiva metoder för stresshantering som kan hjälpa dig att:
                    <br/>
                    <br/>
                    - Förebygga stress
                    <br/>
                    - Hantera stress i stunden
                    <br/>
                    - Återhämta dig från stress
                    <br/>
                </div>
                <div class="threeGridColumnWidth">
                    <h2>Föreläsningar</h2>
                    Jag föreläser om utmattning, stress, hållbarhet och återhämtning. 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'StressComponent',
        components : {
        },
        data : () => ({})
    }
</script>

<style>
.stressImage {
    background-image: url('../assets/IMG_0358.png')
}
</style>